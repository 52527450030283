import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { commitStep, getStepLoading, getData } from '../../store/onboarding';
import { useDispatch, useSelector } from 'react-redux';
import FileUpload from '../FileUpload';
import FoodIcon from '../../images/icon-menus.svg';
import DrinksIcon from '../../images/icon-drinks.svg';
import { Button, Row, Col } from 'antd';

function VenueMenusForm() {
  const { handleSubmit } = useForm();
  const [foodMenus, setFoodMenus] = useState([]);
  const [drinkMenus, setDrinkMenus] = useState([]);
  const [foodMenusError, setFoodMenusError] = useState(false);
  const [foodMenusToRemove, setFoodMenusToRemove] = useState([]);
  const [drinkMenusToRemove, setDrinkMenusToRemove] = useState([]);
  const storeData = useSelector(getData);
  const loading = useSelector(getStepLoading);
  const dispatch = useDispatch();

  const onSubmit = () => {
    switch (true) {
      case (foodMenus.length === 0):
        setFoodMenusError('At least one menu file is required!');
        break;
      default:
        dispatch(commitStep({
          step: 3,
          data: {
            foodMenus,
            foodMenusToRemove,
            drinkMenus,
            drinkMenusToRemove
          },
          nextStep: 'payment-setup'
        }));
        break;
    }
  };

  const onChangeFoodMenus = (file) => {
    setFoodMenus([...foodMenus, ...file])
    setFoodMenusError()
  }

  const onRemoveFoodMenus = (file) => {
    setFoodMenus(foodMenus.filter(e => (e instanceof File ? e.name : e.filename) !== file.name))
    if (file._id) {
      setFoodMenusToRemove([...foodMenusToRemove, file])
    }
  }

  const onChangeDrinkMenus = (file) => {
    setDrinkMenus([...drinkMenus, ...file])
  }

  const onRemoveDrinkMenus = (file) => {
    setDrinkMenus(drinkMenus.filter(e => (e instanceof File ? e.name : e.filename) !== file.name))
    if (file._id) {
      setDrinkMenusToRemove([...drinkMenusToRemove, file])
    }
  }

  const renderError = error => <p className="error">{error}</p>;

  useEffect(() => {
    if (storeData) {
      if (storeData.foodMenuDoc && storeData.foodMenuDoc.length > 0) {
        setFoodMenus(storeData.foodMenuDoc);
      }
      if (storeData.foodMenuPdf && storeData.foodMenuPdf.length > 0) {
        setDrinkMenus(storeData.foodMenuPdf);
      }
    }
  }, [storeData]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <legend>Upload Your Menus</legend>
      <Row className="menus-form">
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <FileUpload
            multiple
            required
            dragAreaContent={<img src={FoodIcon} alt="Food Menus" />}
            buttonName="Upload File(s)"
            name="foodMenus"
            label="* Food Menus"
            error={foodMenusError && renderError(foodMenusError)}
            requireText={<>We require text format versions of your menus.. Doc, PDF</>}
            onChange={onChangeFoodMenus}
            accept={['pdf', 'doc', 'docx']}
            maxSize={2048}
            defaultFiles={foodMenus.map(e => ({name: e instanceof File ? e.name : e.filename, ...e}))}
            onFileRemove={file => onRemoveFoodMenus(file)}
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <FileUpload
            multiple
            dragAreaContent={<img src={DrinksIcon} alt="Drink Menus" />}
            buttonName="Upload File(s)"
            name="drinkMenus"
            label="Drink Menus"
            requireText={<>We require text format versions of your menus.. Doc, PDF</>}
            onChange={onChangeDrinkMenus}
            accept={['pdf', 'doc', 'docx']}
            maxSize={2048}
            defaultFiles={drinkMenus.map(e => ({name: e instanceof File ? e.name : e.filename, ...e}))}
            onFileRemove={file => onRemoveDrinkMenus(file)}
          />
        </Col>
      </Row>
      <div className="flex column flex-end mb-30">
        <Button type="primary" size="large" loading={loading} htmlType="submit">
          Next
        </Button>
      </div>
    </form>
  );
}

export default VenueMenusForm;