import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { handleAuthentication } from '../services/Authentication';
import { createOrUpdateUser, getUserAccounts } from '../services/User';
import { associateAccountUsers, addAccount, getAccount } from '../services/Account';
import { setData } from './onboarding';
import config from '../config'

export const doLoginCallback = createAsyncThunk(
  'session/AUTH_CALLBACK',
  async (_, { dispatch, getState }) => {
    const { organization } = getState().onboarding.data;
    const { authResult } = await handleAuthentication();
    const user = (await createOrUpdateUser(authResult.idTokenPayload.sub)).data;
    dispatch(setUser(user));

    let account = config.defaultAccountId;
    const accountSession = JSON.parse(sessionStorage.getItem('account'))
    if (accountSession && accountSession.emailAddress) {
      let accountState = getState().session.account;
      const { companyName, phoneField, emailAddress} = accountSession
      accountState = {
        ...accountState,
        name: companyName,
        email: emailAddress,
        phone: phoneField,
        organization
      }
      const { data: { _id: accountId } } = await addAccount(accountState);
      account = accountId
    } else if (accountSession && accountSession.account) {
      account = accountSession.account
    }
    const { data } = (await getUserAccounts(user._id)).data;
    const userAccounts = data.filter(managed_user => managed_user.account != null).map(managed_user => managed_user.account);
    if (!userAccounts.includes(account)) {
      await associateAccountUsers({
        user: user._id,
        account,
        role: 3, //read-only = 1, read-write = 2, administrator = 3, super-admin = 4
        scope: [1], //B2C = 1, B2B = 2
        venues: [],
        verified: true,
        organization
      })
    }
    const { data: accountResult } = await getAccount(account);
    dispatch(setAccount(accountResult));
    dispatch(setData({ account, user: user._id }))

    return authResult;
  }
);

export const initWithToken = createAsyncThunk(
  'session/INIT_WITH_TOKEN',
  async code => {
    console.log(`TOKEN`, code, process.env);
    sessionStorage.setItem('account', JSON.stringify(code))
  }
);

export const resetPersistedState = createAsyncThunk(
  'session/RESET_PERSISTED_STATE',
  async () => {
    localStorage.clear()
  }
);

export const sessionSlice = createSlice({
  name: 'session',
  initialState: {
    authentication: false,
    user: false,
    usedAuthView: 'signin',
    showLandingMobile: true,
    account: {
      name: '',
      email: '',
      address: '',
      VAT: '',
      phone: '',
      startDate: null,
      endDate: null,
      liveDate: null,
      report: {},
      socialmedia: {},
      task_completed: false
    }
  },
  reducers: {
    toggleMobileLanding: state => {
      state.showLandingMobile = !state.showLandingMobile;
    },
    setUsedAuthView: (state, action) => {
      state.usedAuthView = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setAccount: (state, action) => {
      state.account = { ...state.account, ...action.payload };
    }
  },
  extraReducers: {
    [doLoginCallback.fulfilled]: (state, action) => {
      state.authentication = {
        expires_in: action.payload.expiresIn,
        expires_at: action.payload.expiresIn * 1000 + new Date().getTime(),
        access_token: action.payload.accessToken,
        id_token: action.payload.idToken
      };
    }
  }
});

export const {
  setUsedAuthView,
  toggleMobileLanding,
  setUser,
  setAccount
} = sessionSlice.actions;

export const getMobileLanding = state => state.session.showLandingMobile;
export const getUsedAuthView = state => state.session.usedAuthView;
export const getSession = state => state.session.authentication;
export const getUser = state => state.session.user;
export const getAccountData = state => state.session.account;

export default sessionSlice.reducer;