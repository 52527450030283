import { BlobServiceClient } from '@azure/storage-blob';
import { azureStorage } from '../config';
import { getVenueShortName, normalizeFileName } from '../helpers';
import { toast } from 'react-toastify';
import FileType from 'file-type/browser';

export const getBlobService = (container) => new BlobServiceClient(
  `https://${azureStorage.account}.blob.core.windows.net/${container}${azureStorage.sas}`
);

export const convertFileName = fileName => `${new Date().getTime()}-${normalizeFileName(fileName)}`;

export const upload = async (file, mainContainer = 'images') => {
  const remoteFileName = convertFileName(file.name);

  const { ext, mime } = await FileType.fromBlob(file);

  const blobServiceClient = getBlobService(mainContainer).getContainerClient(`${getVenueShortName()}`);

  try {
    const blockBlobClient = blobServiceClient.getBlockBlobClient(remoteFileName);
    const toastId = new Date().getTime();

    const progressToast = toast.info(`Uploading: ${file.name} (0%)`, {
      progress: 1,
      toastId: toastId
    });

    const uploadProcess = await blockBlobClient.uploadBrowserData(file, {
      onProgress: ({ loadedBytes }) => {
        const progress = Math.round((loadedBytes / file.size) * 100);

        if (progress < 100) {
          toast.update(progressToast, {
            render: `Uploading: ${file.name} (${progress}%)`,
            progress: progress,
            toastId: toastId
          });         
        } else {
          toast.update(progressToast, {
            render: `Uploading: ${file.name} (done)`,
            progress: progress,
            toastId: toastId
          });
        }
      },
      blobHTTPHeaders: { blobContentType: mime }
    });

    return {
      fileExtension: ext,
      fileType: mime,
      fileName: remoteFileName,
      publicPath: `${azureStorage.realUrl}/${mainContainer}/${getVenueShortName()}/${remoteFileName}`,
      ...uploadProcess
    };
  } catch (e) {
    throw e;
  }
};