import React from 'react';
import TypeWriter from 'react-rotating-text';
import useWindowSize from '@rehooks/window-size';
import { useDispatch } from 'react-redux';
import { shuffle } from 'lodash';
import { toggleMobileLanding } from '../store/session';
import Logo from './Logo';

function Landing() {
  const { innerWidth } = useWindowSize();
  const dispatch = useDispatch();

  const closeLandingPage = () => {
    dispatch(toggleMobileLanding());
  };

  const LandingContent = () => (
    <h1>
      Your Restaurant Menus, <br />Translated from digital into… <br />
      <span>
        <TypeWriter items={shuffle([
          'Français',
          'Italiano',
          'Español',
          'Português',
          '한국어',
          '日本語',
          '简体中文'
        ])} />
      </span>
    </h1>
  );

  return innerWidth > 640 ? (
    <section className="landing">
      <Logo />
      <LandingContent />
      <div className="clouds">
        <div />
        <div />
        <div />
        <div />
      </div>
    </section>
  ) : (
    <section className="landing">
      <div className="logo">
        <Logo type="dark" />
      </div>
      <div className="content">
        <LandingContent />
      </div>
      <button onClick={closeLandingPage}>CREATE YOUR PASSWORD</button>
    </section>
  );
}

export default Landing;