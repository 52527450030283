class ChargebeeHandler {
  constructor(successCallback, closedCallback) {
    this.successCallback = successCallback;
    this.closedCallback = closedCallback;
    this.setup();
  }

  checkoutPackage(name, customer) {
    try {
      this.product = this.instance.initializeProduct(name);
      this.cart.setCustomer(customer);
      this.cart.replaceProduct(this.product);
      this.cart.proceedToCheckout();
    } catch (error) {
      console.error(`Checkout Error:`, error);
    }
  }

  setup() {
    try {
      this.instance = window.Chargebee.getInstance();
      this.cart = this.instance.getCart();

      this.instance.setCheckoutCallbacks(() => {
        return {
          success: this.successCallback,
          close: this.closedCallback
        };
      });
    } catch (error) {
      console.error(`[Chargebee] Setup Error:`, error);
    }
  }
}

export default ChargebeeHandler;