import React from 'react';
import { Card, Button } from 'antd';
import { RightCircleOutlined } from '@ant-design/icons';

function PaymentPackage(props) {

  const renderFeature = ({ text, mark }, index) => <li key={index}>✔ {text}{mark && <span className="tag">included</span>}</li>;

  return (
    <Card
      className="package"
      hoverable
      type="inner"
      title={props.title}
      extra={<RightCircleOutlined style={{color: '#fff', fontSize: '25px'}} onClick={props.action} />}
      actions={[
        <Button size="large" onClick={props.action}>Select</Button>
      ]}
    >
      <div className="details flex column">
        <div className="features">
          <h2>{props.planTitle}</h2>
          <h4>Provide a digital menu in multi-languages.</h4>
          <ul>
            {props.features.map(renderFeature)}
          </ul>
        </div>
        <hr />
        <div className="period-pricing flex row center space-between">
          <div className="period">
            <h2>{props.period}</h2>
            <h4>{props.periodDesc} {props.autoRenews && <span className="tag">Auto-renew</span>}</h4>
          </div>
          <div className="price">
            <h4>{props.amountText}</h4>
            <h1>{props.amount}<span>/{props.period === 'Annual' ? 'yr' : 'mo'}</span></h1>
          </div>
        </div>
      </div>
    </Card>
  )
}

export default PaymentPackage;