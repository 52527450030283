import './styles/app.less';
import '@reach/combobox/styles.css';
import 'react-toastify/dist/ReactToastify.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { PersistGate } from 'redux-persist/integration/react';
import * as serviceWorker from './serviceWorker';
import { Provider as ReduxProvider } from 'react-redux';
import { store, persistor } from './store';
import Application from './components/Application';
import Loading from './components/Loading';
import { ToastContainer } from 'react-toastify';

ReactDOM.render(
    <ReduxProvider store={store}>
      <PersistGate loading={<Loading />} persistor={persistor}>
        <Application />
        <ToastContainer />
      </PersistGate>
    </ReduxProvider>,
  document.getElementById('app-root')
);

if (process.env.NODE_ENV === 'development') {
  serviceWorker.unregister();
} else {
  serviceWorker.register();
}
