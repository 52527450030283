import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import useWindowSize from '@rehooks/window-size';
import { toast } from 'react-toastify';
import { setUsedAuthView } from '../store/session';
import { login } from '../services/Authentication';
import Logo from './Logo';
import { Form, Input, Button } from 'antd';

function LoginForm() {
  const dispatch = useDispatch();
  const { innerWidth } = useWindowSize();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onSubmit = async data => {
    setLoading(true);
    dispatch(setUsedAuthView('signin'));
    try {
      await login(data);
      setLoading(false);
    } catch ({ description }) {
      setLoading(false);
      toast.error(description);
    }
  };

  const onSubmitFailed = (error) => {
    console.log('Failed:', error);
    toast.error(error);
  };

  return (
    <>
      {innerWidth > 640 && (
        <header>
          <h1>Welcome to</h1>
          <Logo type="dark" />
        </header>
      )}
      <Form
        layout="vertical"
        form={form}
        onFinish={onSubmit}
        onFinishFailed={onSubmitFailed}
      >
        <legend>Enter your Sign in details</legend>
        <Form.Item 
          name="email" 
          rules={[
            {
              type: 'email',
              message: 'Entered value needs to be an email address.'
            },
            {
              required: true,
              message: 'Please input your email address.'
            }
          ]}
        >
          <Input 
            size="large" 
            placeholder="Email" />
        </Form.Item>
        <Form.Item 
          name="password"
          rules={[
            {
              required: true,
              message: 'Please input your password.'
            }
          ]}
        >
          <Input.Password 
            size="large" 
            placeholder="Password" />
        </Form.Item>
        <Button 
          type="primary" 
          size="large" 
          loading={loading} 
          htmlType="submit"
        >Sign In</Button>
      </Form>
      <div className="form-footer">Do not have an account? <Link to="/">Sign up now</Link></div>
    </>
  );
}

export default LoginForm;