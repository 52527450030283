import React, { useState, useRef, useEffect } from 'react';
import 'react-image-crop/dist/ReactCrop.css';
import ReactCrop from 'react-image-crop';
import { FileDrop } from 'react-file-drop';
import { GoTrashcan } from 'react-icons/go';
import { Button } from 'antd';
import { toast } from 'react-toastify';

function FileUpload(props) {
  const fileInput = useRef();
  const imageRef = useRef();
  const [crop, setCrop] = useState({
    aspect: 1,
    unit: '%',
    width: 100
  });

  const [fileList, setFileList] = useState([]);
  const [croppedImage, setCroppedImage] = useState();
  const [src, setSrc] = useState();

  const handleClick = event => {
    event.preventDefault();
    fileInput.current.click();
  };

  const humanFileSize = (bytes) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1000,
      sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
      i = Math.floor(Math.log(bytes) / Math.log(k));
    return Math.round((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  };

  const formatFileList = files => {
    if (files) {
      let fileArray = [];
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const { name, size, type } = file;
        const ext = name.split('.').pop();

        switch (true) {
          case (fileList.find(i => i.name === file.name)):
            toast.error(`File ${file.name} already exists`);
            break;
          case !(props.accept && new Set(props.accept).has(ext)):
            toast.error(`File ${file.name} not valid only accepts ${props.accept}`);
            break;
          case !(props.maxSize && size < (props.maxSize * 1000)):
            toast.error(`File ${file.name} size exceeded only accept maximum of ${humanFileSize(props.maxSize * 1000)}`);
            break;
          default:
            fileArray.push(file);
            if (!fileList.find(i => i.name === name)) {
              setFileList(fileList => [...fileList, {
                name: name,
                size: size,
                type: type,
              }]);
            }
            break;
        }
      }

      if (props.cropper) {
        if (fileArray.length) {
          const reader = new FileReader();
          reader.addEventListener('load', () => {
            setSrc(reader.result);
          });
          reader.readAsDataURL(files[0]);
        }
      } else {
        if (props.onChange) props.onChange(fileArray);
      }
    }
  };

  const handleFileChange = e => {
    if (e.target.files && e.target.files.length > 0) {
      formatFileList(e.target.files);
    }
  };

  const getCroppedImg = (image, crop, fileName) => {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;

    canvas.getContext('2d').drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          reject(new Error('Failed to get image: canvas empty!'));
          return;
        }

        blob.name = fileName;
        window.URL.revokeObjectURL(croppedImage);

        props.onChange(blob);

        resolve(window.URL.createObjectURL(blob));
      }, 'image/png');
    });
  };

  const makeClientCrop = async crop => {
    if (imageRef.current && crop.width && crop.height) {
      let filename = ''
      if (props.defaultSrcName) {
        filename = props.defaultSrcName
      } else {
        filename = fileInput.current.files[0].name
      }
      const croppedImageUrl = await getCroppedImg(
        imageRef.current,
        crop,
        filename
      );
      setCroppedImage(croppedImageUrl);
    }
  };

  const onImageLoaded = image => imageRef.current = image;

  const removeFile = (file, event) => {
    event.preventDefault();
    if (props.onFileRemove) props.onFileRemove(file);
    setFileList(fileList.filter(i => i.name !== file.name));
  };

  useEffect(() => {
    if (props.defaultFiles && props.defaultFiles.length > 0) {
      setFileList(props.defaultFiles)
    }
    if (props.defaultSrc) setSrc(props.defaultSrc)
  }, [props.defaultFiles, props.defaultSrc]);

  return (
    <>
      <label>{props.label}</label>
      <input type="file" multiple={props.multiple} onChange={handleFileChange} ref={fileInput} />
      <div className="file-upload">
        <div className="flex row align-center">
          <Button type="primary" size="large" onClick={handleClick}>{props.buttonName}</Button> {!props.multiple && <label>{props.requireText}</label>}
        </div>
        {props.error}
        {
          props.multiple && (
            <>
              <div className="file-list">
                {fileList.map((file, index) => (
                  <div className="item" key={index}>{file.name} <button onClick={event => removeFile(file, event)}><GoTrashcan /></button></div>
                ))}
              </div>
              <FileDrop
                onDrop={(files, event) => handleFileChange({
                  ...event,
                  target: {
                    files: files
                  }
                })}
              >
                <div className="drag-drop">
                  <div className="wrapper flex column center align-center">
                    {props.dragAreaContent}
                    <h4>Drag and Drop the file(s) here or <span onClick={handleClick}>choose file(s)</span></h4>
                    <label>{props.requireText}</label>
                  </div>
                </div>
              </FileDrop>
            </>
          )
        }
        {props.cropper && (
          <div className="flex row cropper space-even">
            <div className="flex column">
              <div className="crop">
                {src && <ReactCrop
                  src={src}
                  crop={crop}
                  ruleOfThirds
                  onImageLoaded={onImageLoaded}
                  onComplete={makeClientCrop}
                  onChange={setCrop}
                />}
              </div>
              <label>Adjust the logo</label>
            </div>

            <div className="flex column">
              <div className="preview flex row align-center space-even">
                <div className="round-corner">
                  {croppedImage && (
                    <img alt="CropStandart" src={croppedImage} />
                  )}
                </div>
                <div className="circle">
                  {croppedImage && (
                    <img alt="CropCircle" src={croppedImage} />
                  )}
                </div>
              </div>
              <label>How they look in different shapes</label>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default FileUpload;