import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getSession, initWithToken } from '../store/session';
import { getStep } from '../store/onboarding';
import Registration from '../views/registration';
import Authentication from '../views/authentication';
import Onboarding from '../views/onboarding';
import Callback from '../views/callback';
import { getParams } from '../helpers'

function Application() {
  const dispatch = useDispatch();
  const session = useSelector(getSession);
  const onboardingStep = useSelector(getStep);
  
  useEffect(() => {
    const url = window.location.href
    const params = url.split('?');
    if (params.length > 1 && params[1] !== '') {
      dispatch(initWithToken(getParams(url)));
    }
  });

  return (
    <Router>
      {session ? (
        <Switch>
          <Redirect exact from="/" to={onboardingStep} />
          <Redirect exact from="/auth" to={onboardingStep} />
          <Redirect exact from="/callback" to={onboardingStep} />
          <Route exact path="*" component={Onboarding} />
        </Switch>
      ) : (
          <Switch>
            <Route exact path="/" component={Registration}/>
            <Route exact path="/auth" component={Authentication} />
            <Route exact path="/callback" component={Callback} />
            <Redirect from="*" to="/auth" />
          </Switch>
        )}
    </Router>
  );
}

export default Application;