import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { doLoginCallback } from '../store/session';
import Loading from '../components/Loading';

function Callback() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(doLoginCallback());
  });

  return <Loading color="white" />;
}

export default Callback;