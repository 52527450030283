import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { getMobileLanding, getSession } from '../store/session';
import useWindowSize from '@rehooks/window-size';
import Stepper from '../components/Stepper';
import Landing from '../components/Landing';
import LoginForm from '../components/LoginForm';
import Logo from '../components/Logo';

function Authentication() {
  const mobileLanding = useSelector(getMobileLanding);
  const session = useSelector(getSession);
  const { innerWidth } = useWindowSize();

  const [steps] = useState([
    {
      name: `Sign In`,
      route: `/auth`
    },
    {
      name: `Venue Information`,
      route: `/venue-information`
    },
    {
      name: `Profile Image`,
      route: `/profile-image`
    }
  ]);

  return (
    <>
      { innerWidth > 640 && (
        <>
          <Landing />
          <section className="onboarding">
            <Stepper steps={steps} />
            <main>
              { session === false && <LoginForm />}
            </main>
          </section>
        </>
      )}

      {(innerWidth <= 640 && mobileLanding) && <Landing />}

      {(innerWidth <= 640 && !mobileLanding) && (
        <>
          <header className="full-width text-center">
            <h3>Welcome to</h3>
            <Logo type="dark" />
          </header>
          <section className="onboarding full-width">
            <Stepper steps={steps} />
            <main>
              { session === false && <LoginForm />}
            </main>
          </section>
        </>
      )}
    </>
  );
}

export default Authentication;