import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getCuisines } from '../services/Cuisines';
import { getCities } from '../services/Cities';

export const fetchCuisines = createAsyncThunk(
  'temp/FETCH_CUISINES',
  async () => {
    const { data } = (await getCuisines({
      limit: 1000
    })).data;
    return data;
  }
);

export const fetchCities = createAsyncThunk(
  'temp/FETCH_CITIES',
  async () => {
    const { data } = (await getCities({
      limit: 1000
    })).data;
    return data;
  }
);

export const tempSlice = createSlice({
  name: 'temp',
  initialState: {
    cuisines: [],
    cities: []
  },
  extraReducers: {
    [fetchCuisines.fulfilled]: (state, action) => {
      state.cuisines = action.payload;
    },
    [fetchCities.fulfilled]: (state, action) => {
      state.cities = action.payload;
    }
  }
});

export const cuisines = state => state.temp.cuisines;
export const cities = state => state.temp.cities;

export default tempSlice.reducer;