import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { getMobileLanding } from '../store/session';
import useWindowSize from '@rehooks/window-size';
import Stepper from '../components/Stepper';
import Landing from '../components/Landing';
import Logo from '../components/Logo';
import PasswordForm from '../components/PasswordForm';

function Registration() {
  const mobileLanding = useSelector(getMobileLanding);
  const { innerWidth } = useWindowSize();

  const [steps] = useState([{
    name: `Create Password`,
    route: `/`
  },
  {
    name: `Venue Information`,
    route: `/venue-information`
  },
  {
    name: `Profile Image`,
    route: `/profile-image`
  }]);

   return (
    <>
      { innerWidth > 640 && (
        <>
          <Landing />
          <section className="onboarding">
            <Stepper steps={steps} />
            <main>
              <PasswordForm />
            </main>
          </section>
        </>
      )}

      {(innerWidth <= 640 && mobileLanding) && <Landing />}

      {(innerWidth <= 640 && !mobileLanding) && (
        <>
        <header className="full-width text-center">
          <h3>Welcome to</h3>
          <Logo type="dark" />
        </header>
        <section className="onboarding full-width">
          <Stepper steps={steps} />
          <main>
            <PasswordForm />
          </main>
        </section>
        </>
      )}
    </>
  );
}

export default Registration;