import React, { useState, useEffect } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getUsedAuthView } from '../store/session';
import { getStep } from '../store/onboarding';
import { 
  VenueInfoForm,
  ProfilePictureForm,
  VenueMenusForm,
  PaymentSetup,
  OnboardingComplete
 } from '../components/steps';
import Stepper from '../components/Stepper';

function Onboarding(props) {
  const { pathname } = props.location;
  const usedAuthView = useSelector(getUsedAuthView);
  const currentStep = useSelector(getStep);
  const history = useHistory();

  const getFirstStep = () => {
    switch (usedAuthView) {
      case 'signin':
        return {
          name: `Sign In`,
          route: `/auth`
        }
      case 'signup':
        return {
          name: `Create Password`,
          route: `/`
        }
      default:
        return false;
    }
  };

  const [steps] = useState(
    [
      getFirstStep(),
      {
        name: `Venue Information`,
        route: `/venue-information`,
        component: <VenueInfoForm />
      },
      {
        name: `Profile Image`,
        route: `/profile-image`,
        component: <ProfilePictureForm />
      },
      {
        name: `Upload Menus`,
        route: `/upload-menus`,
        component: <VenueMenusForm />
      }, {
        name: `Payment Setup`,
        route: '/payment-setup',
        component: <PaymentSetup />
      },
      {
        name: `Job Done`,
        route: '/job-done',
        component: <OnboardingComplete />
      }
    ]
  );

  useEffect(() => {
    const getStepByRoute = route => steps.find(i => i.route === route);
    if (getStepByRoute(currentStep).route !== pathname) history.push(currentStep);
  }, [currentStep, steps, history, pathname]);

  useEffect(() => {
    if (currentStep !== '/payment-setup' && currentStep !== '/job-done') {
      const handleBeforeUnLoad = (event) => {
        event.preventDefault();
        return event.returnValue = '';
      }
      window.addEventListener('beforeunload', handleBeforeUnLoad);
      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnLoad);
      }
    }
  });

  return (
    <>
      <section className="onboarding full-width">
        <Stepper steps={steps} />
        <main>
          {steps.find(i => i.route === pathname).component || <h3>Not Found</h3>}
        </main>
      </section>
    </>
  );
}

export default withRouter(Onboarding);