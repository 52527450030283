import { configureStore, combineReducers, getDefaultMiddleware } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import sessionReducer from './session';
import onboardingReducer from './onboarding';
import tempReducer from './temp';
import storage from 'redux-persist/lib/storage';

const reducers = combineReducers({
  session: sessionReducer,
  onboarding: onboardingReducer,
  temp: tempReducer
});

const rootReducer = (state, action) => {
  if (action.type === 'session/RESET_PERSISTED_STATE/fulfilled') {
    storage.removeItem('persist:root')
    state = undefined;
  }
  return reducers(state, action);
};

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['onboarding', 'session']
};

export const store = configureStore({
  reducer: persistReducer(persistConfig, rootReducer),
  middleware: getDefaultMiddleware({ serializableCheck: false })
});

export const persistor = persistStore(store);