import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { addVenueInfo, updateVenueInfo, getPopulatedVenueInfo } from '../services/Onboarding';
import { upload } from '../services/FileStorage';
import { addImage } from '../services/Image';
import { addDocument } from '../services/Document';
import { toast } from 'react-toastify';

export const commitStep = createAsyncThunk(
  'onboarding/COMMIT_STEP',
  async ({ step, data, nextStep }, { getState, dispatch }) => {
    console.log(`commit -> step:`, step, `next:`, nextStep);

    try {
      let state = getState().onboarding.data;
      const { _id } = getState().session.user;

      dispatch(setStepLoading());

      switch (step) {
        case 1:
          // venue info
          state = {
            ...state,
            address: data.address,
            name: data.name,
            city: data.city,
            cuisine: {
              primary: data.primaryCuisine,
              secondary: data.secondaryCuisine || ''
            },
            short_name: data.short_name,
            place_id: data.place_id || state.place_id,
            openingHours: data.openingHours || state.openingHours,
            resvPhoneNum: data.resvPhoneNum
          };
        break;
        case 2:
          // profile picture
          const { logo, background } = data;

          let fileEntries = {};

          for (const file of [{
            data: logo,
            ref: 'primaryImage'
          }, {
            data: background,
            ref: 'entryImage'
          }]) {
            if (file.data) {
              const uploadResult = await upload(file.data, 'images');

              const addImageData = await addImage({
                name: file.name,
                fileName: file.name,
                url: uploadResult.publicPath,
                uploadedBy: _id,
                venue: state._id,
                organization: state.organization
              });

              fileEntries = {
                ...fileEntries,
                [file.ref]: addImageData.data.id
              };
            }
          }

          state = {
            ...state,
            ...fileEntries
          };
        break;
        case 3:
          // upload menus
          const { foodMenus, drinkMenus, foodMenusToRemove, drinkMenusToRemove } = data;

          let fileCategories = {
            'foodMenuDoc': [],
            'foodMenuPdf': []
          };

          for (const menu of [{
            data: foodMenus,
            ref: 'foodMenuDoc'
          }, {
            data: drinkMenus,
            ref: 'foodMenuPdf'
          }]) {
            if (menu.data.length) {
              for (let file of menu.data) {
                if (file instanceof File) {
                  const { publicPath } = await upload(file, 'menu');

                  const addDocData = await addDocument({
                    filename: file.name,
                    url:  publicPath,
                    venue: state._id,
                    uploader: _id,
                    ad: null,
                    onboarding: null,
                    organization: state.organization
                  });

                  fileCategories[menu.ref].push(addDocData.data._id)
                }
              }
            }
          }

          state = {
            ...state,
            ...{ foodMenuDoc: [...state.foodMenuDoc.filter((doc) => !foodMenusToRemove.find((e) => doc._id === e._id)), ...fileCategories.foodMenuDoc] },
            ...{ foodMenuPdf: [...state.foodMenuPdf.filter((pdf) => !drinkMenusToRemove.find((e) => pdf._id === e._id)), ...fileCategories.foodMenuPdf] }
          };
        break;
        case 4:
          // payment setup
          dispatch(setPaymentComplete());
          state = {
            ...state,
            onboarding_status: 'for_approval'
          };
        break;
        default:
          toast.error(`Invalid step..`);
        break;
      }

      state.process = step;

      if (state.account) {
        if (state._id) {
          await updateVenueInfo(state._id, state);
        } else {
          const { data } = await addVenueInfo(state);
          state._id = data._id;
        }
  
        const update = await getPopulatedVenueInfo(state._id);
  
        dispatch(setStep(`/${nextStep}`));
  
        return update.data;
      } else {
        toast.error(`Can't create new venue due to account not found!`, { 
          autoClose: 10000 
        });
      }
    } catch (error) {
      let message = ``;
      switch (error.response.data.message) {
        case 'Name already exist':
          message = `Venue Name is not available!`;
          break;
        default:
          message = error.response.data.message;
          break;
      }
      toast.error(message);
      return {};
    }
  }
);

export const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState: {
    currentStep: '/venue-information',
    errors: [],
    stepLoading: false,
    paymentComplete: false,
    data: {
      organization: '5ed4ef97c318ff43e56edfd9', // Default organization for VivaCity
      city: '',
      cuisine: {
        primary: '',
        secondary: ''
      },
      foodMenuDoc: [],
      foodMenuPdf: [],
      images: [],
      name: '',
      onboarding_status: 'draft',
      process: 0,
      short_name: '',
      tags: [],
      tips: {
        tip1: '',
        tip2: '',
        tip3: ''
      },
      welcomeMessage: '',
      address: '',
      place_id: '',
      venue_type: 'restaurant',
      categories: [],
      resvPhoneNum: '',
      resvEmail: '',
      bookingURL: '',
      openingHours: {}
    }
  },
  reducers: {
    setData: (state, action) => {
      state.data = {
        ...state.data,
        ...action.payload
      };
    },
    setStep: (state, action) => {
      state.currentStep = action.payload;
    },
    setStepLoading: state => {
      state.stepLoading = true;
    },
    setPaymentComplete: state => {
      state.paymentComplete = true;
    }
  },
  extraReducers: {
    [commitStep.fulfilled]: (state, action) => {
      console.log(`commit completed`, action.payload);
      state.stepLoading = false;
      state.data = { ...state.data, ...action.payload };
    },
    [commitStep.rejected]: (state, action) => {
      toast.warning(`Commit was rejected...`, action.payload);
      state.stepLoading = false;
    }
  }
});

export const { setStep, setData, setStepLoading, setPaymentComplete } = onboardingSlice.actions;
export const getStep = state => state.onboarding.currentStep;
export const getData = state => state.onboarding.data;
export const getStepLoading = state => state.onboarding.stepLoading;
export const getPaymentComplete = state => state.onboarding.paymentComplete;

export default onboardingSlice.reducer;