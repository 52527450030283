import axios from 'axios';

const {
  NODE_ENV,
  REACT_APP_PRODUCT_TITLE,
  REACT_APP_AUTH0_DOMAIN,
  REACT_APP_AUTH0_CLIENT_ID,
  REACT_APP_AUTH0_REALM,
  REACT_APP_AUTH0_REDIRECT_URL,
  REACT_APP_AUTH0_PORTAL_REDIRECT_URL,
  REACT_APP_BASE_API_URL,
  REACT_APP_BASE_API_KEY,
  REACT_APP_MS_CATEGORY_API_URL,
  REACT_APP_MS_VENUE_API_URL,
  REACT_APP_MS_API_KEY,
  REACT_APP_AZ_STORAGE_ACCOUNT,
  REACT_APP_AZ_STORAGE_AUTH,
  REACT_APP_AZ_STORAGE_DIR,
  REACT_APP_TEMP_IMAGE_BASEURL,
  REACT_APP_CHARGEBEE_STARTER_MONTHLY_PLAN,
  REACT_APP_CHARGEBEE_STARTER_ANNUAL_PLAN,
  REACT_APP_DEFAULT_ACCOUNT_ID
} = process.env;

export const msBaseAxios = axios.create({
  baseURL: REACT_APP_BASE_API_URL,
  headers: {
    'Ocp-Apim-Subscription-Key': REACT_APP_BASE_API_KEY
  }
});

export const msCategoryAxios = axios.create({
  baseURL: REACT_APP_MS_CATEGORY_API_URL,
  headers: {
    'vivacity-subscription': REACT_APP_MS_API_KEY
  }
});

export const msVenueAxios = axios.create({
  baseURL: REACT_APP_MS_VENUE_API_URL,
  headers: {
    'vivacity-subscription': REACT_APP_MS_API_KEY
  }
});

export const azureStorage = {
  account: REACT_APP_AZ_STORAGE_ACCOUNT,
  sas: REACT_APP_AZ_STORAGE_AUTH,
  container: REACT_APP_AZ_STORAGE_DIR,
  realUrl: REACT_APP_TEMP_IMAGE_BASEURL
}

export default {
  appTitle: REACT_APP_PRODUCT_TITLE,
  dev: NODE_ENV === 'development',
  chargebeeStarterMonthlyPlan: REACT_APP_CHARGEBEE_STARTER_MONTHLY_PLAN,
  chargebeeStarterAnnualPlan: REACT_APP_CHARGEBEE_STARTER_ANNUAL_PLAN,
  defaultAccountId: REACT_APP_DEFAULT_ACCOUNT_ID,
  portalRedirectUri: REACT_APP_AUTH0_PORTAL_REDIRECT_URL,
  auth0: {
    domain: REACT_APP_AUTH0_DOMAIN,
    clientID: REACT_APP_AUTH0_CLIENT_ID,
    redirectUri: REACT_APP_AUTH0_REDIRECT_URL,
    realm: REACT_APP_AUTH0_REALM,
    responseType: 'token id_token',
    scope: 'openid profile'
  }
};