import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { commitStep, getData, getStepLoading } from '../../store/onboarding';
import { fetchCuisines, cuisines, fetchCities, cities } from '../../store/temp';
import { getPopulatedGooglePlace } from '../../services/Onboarding';
import usePlacesAutocomplete from 'use-places-autocomplete';
import { generateShortName } from '../../helpers'
import { Form, Input, Select, Button, AutoComplete, Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

function VenueInfoForm() {
  const cityList = useSelector(cities)
  const cuisineList = useSelector(cuisines);
  const storeData = useSelector(getData);
  const loading = useSelector(getStepLoading);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { suggestions: { data }, setValue } = usePlacesAutocomplete();
  const [googlePlaceData, setGooglePlaceData] = useState({
    resvPhoneNum: '',
    place_id: '',
    openingHours: {}
  });

  const onSearchPlace = value => {
    setValue(value);
  };

  const options = data.length > 0 && data.map((suggestion) => {
    const { description } = suggestion;
    return {
      label: description,
      value: description,
      ...suggestion
    }
  });

  const handleSelect = async (value, option) => {
    try {
      const { data } = await getPopulatedGooglePlace(option.place_id);
      const { full_address } = data.address;
      setGooglePlaceData({
        ...googlePlaceData,
        resvPhoneNum: data.resvPhoneNum,
        place_id: option.place_id,
        openingHours: data.openingHours || {}
      });
      form.setFieldsValue({
        name: data.name,
        address: full_address,
        city: option.terms && option.terms[2] && cityList.map(e => e.shortname).includes(option.terms[2].value) ? option.terms[2].value : undefined
      });
    } catch (e) {
      toast.warning(`Failed to retrieve place information.`);
    }
  }

  const onSubmit = (data) => {
    dispatch(commitStep({
      step: 1,
      data: {
        ...data,
        ...googlePlaceData,
        short_name: generateShortName(data.name)
      },
      nextStep: 'profile-image'
    }));
  };

  const onSubmitFailed = (error) => {
    console.log('Failed:', error);
    toast.error(error);
  };

  const formItemLayout = {
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 12 }
    }
  };

  useEffect(() => {
    dispatch(fetchCities())
    dispatch(fetchCuisines());

    if (storeData) {
      form.setFieldsValue({
        name: storeData.name,
        address: storeData.address,
        city: storeData.city || undefined,
        primaryCuisine: storeData.cuisine.primary || undefined,
        secondaryCuisine: storeData.cuisine.secondary || undefined
      });
      setGooglePlaceData({
        resvPhoneNum: storeData.resvPhoneNum,
        place_id: storeData.place_id,
        openingHours: storeData.openingHours || {}
      });
    }

  }, [dispatch, storeData, form]);

  return (
    <Form
      {...formItemLayout}
      layout="vertical"
      form={form}
      onFinish={onSubmit}
      onFinishFailed={onSubmitFailed}
    >
      <h3>Venue Information</h3><br/>
      <Form.Item 
        name="name" 
        label={
          <span>
            Venue Name&nbsp;
            <Tooltip 
              defaultVisible={!storeData.name}
              color="#b19b63"
              placement="right"
              title="Type your venue name and then select the right venue from the Google Place list">
              <QuestionCircleOutlined style={{color: '#b19b63'}} />
            </Tooltip>
          </span>
        }
        rules={[{ required: true, message: 'Please add venue name' }]}
      >
        <AutoComplete options={options} onChange={onSearchPlace} onSelect={handleSelect}>
          <Input size="large" placeholder="Venue Name" />
        </AutoComplete>
      </Form.Item>
      <Form.Item 
        name="address" 
        label="Address" 
      >
        <Input 
          size="large" 
          placeholder="Address" />
      </Form.Item>
      <Form.Item 
        name="city" 
        label="City" 
        rules={[{ required: true, message: 'Please select city' }]}
      >
        <Select
          placeholder="City"
          showSearch
          allowClear
          size="large"
        >
          {cityList.map((e) => <Select.Option key={e.id} value={e.shortname}>{e.name.en}</Select.Option>)}
        </Select>
      </Form.Item>
      <Form.Item 
        name="primaryCuisine" 
        label="Cuisine" 
        rules={[{ required: true, message: 'Please select primary cuisine' }]}
      >
        <Select
          placeholder="Primary"
          showSearch
          allowClear
          size="large"
        >
          {cuisineList.map((e) => <Select.Option key={e.id} value={e.name}>{e.name}</Select.Option>)}
        </Select>
      </Form.Item>
      <Form.Item 
        name="secondaryCuisine" 
      >
        <Select
          placeholder="Secondary"
          showSearch
          allowClear
          size="large"
        >
          {cuisineList.map((e) => <Select.Option key={e.id} value={e.name}>{e.name}</Select.Option>)}
        </Select>
      </Form.Item>
      <div className="flex column flex-end mb-30">
        <Button type="primary" size="large" loading={loading} htmlType="submit">
          Next
        </Button>
      </div>
    </Form>
  );
}

export default VenueInfoForm;