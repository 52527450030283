import React from 'react';
import ReactLoading from 'react-loading';
import Logo from './Logo';

function Loading(props) {
  return (
    <div className="loading-wrapper flex align-center column">
      <header className="text-center">
        <h3>Welcome to</h3>
        <Logo />
      </header>
      <ReactLoading type="spokes" color={props.color || "black"} height={60} width={60} />
    </div>
  );
}

export default Loading;