import React from 'react';
import { withRouter } from 'react-router-dom';
import useWindowSize from '@rehooks/window-size';
import useDocumentTitle from '@rehooks/document-title';
import { useSelector, useDispatch } from 'react-redux';
import { getSession } from '../store/session';
import { setStep } from '../store/onboarding';
import config from '../config';
import Logo from './Logo';

function Stepper(props) {
  const { steps, location } = props;
  const { pathname } = location;
  const session = useSelector(getSession);
  const dispatch = useDispatch();
  const currentStep = steps.find(i => i.route === pathname);
  const { innerWidth } = useWindowSize();

  useDocumentTitle(`${config.appTitle} - ${currentStep.name}`);

  const isStepLess = item => {
    const validate = steps.indexOf(item) <= steps.indexOf(currentStep);
    return validate;
  };

  const fallbackClass = item => isStepLess(item) ? 'complete' : '';

  const isPrevious = item => {
    const index = steps.findIndex(e => e.route === pathname)
    return steps[index - 1] && steps[index - 1].route === item.route;
  };

  const prevClass = (item) => isPrevious(item) ? ' prev' : '';

  const renderStepItem = name => (
    <>
      <div className="circle"></div>
      <div className="label">
        {name}
      </div>
    </>
  );

  const createMenuItem = (item, index) => {
    switch (true) {
      case index === 0:
        return (
          <li className={[pathname === item.route ? 'active' : 'complete', prevClass(item)].join('')} key={item.route}>
            {renderStepItem(item.name)}
          </li>
        );
      case pathname === '/job-done':
        return (
          <li className={[pathname === item.route ? 'active last' : fallbackClass(item), prevClass(item)].join('')} key={item.route}>
            {renderStepItem(item.name)}
          </li>
        );
      case index > 0 && isStepLess(item):
        return (
          <li onClick={() => dispatch(setStep(item.route))} className={[pathname === item.route ? 'active' : fallbackClass(item), prevClass(item)].join('')} key={item.route}>
            {renderStepItem(item.name)}
          </li>
        );
      default:
        return (
          <li key={item.route}>
            {renderStepItem(item.name)}
          </li>
        );
    }
  };

  return (
    <nav>
      <ul className={session ? 'steps' : ''}>
        {steps.map(createMenuItem)}
        {(session && innerWidth <= 640) && <li />}
      </ul>
      {session && <Logo type="dark" />}
    </nav>
  );
}

export default withRouter(Stepper);