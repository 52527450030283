import React from 'react';
import Logo from '../Logo';
import Artwork from '../../images/illustration2.svg';
import { portalRedirectUrl } from '../../helpers';
import { useDispatch } from 'react-redux';
import { resetPersistedState } from '../../store/session';
import { Button } from 'antd';

function OnboardingComplete() {
  const dispatch = useDispatch();

  const redirectUrl = () => {
    dispatch(resetPersistedState());
    return window.location.href = portalRedirectUrl();
  };

  return (
    <div className="flex full-width row center space-between">
      <div className="onboarding-end">
        <h1>Job Done!</h1>
        <p>
          We’re excited to have you onboard!<br />
        We’ll fire over an email as soon as your digital menu is live.<br />
          <br />
        Thanks,
      </p>
        <Logo type="beige" />
        <legend>Team VIVA CITY</legend>
      </div>

      <div className="flex row flex-end">
        <div className="artwork">
          <img src={Artwork} alt="VivaCity" />
        </div>
        <Button type="primary" size="large" onClick={redirectUrl}>Return to Dashboard</Button>
      </div>
    </div>
  );
}

export default OnboardingComplete;