import { msVenueAxios } from '../config';
import { getToken } from '../helpers';

export const addAccount = data => msVenueAxios.post(`accounts?organization=${data.organization}`, data, {
  headers: {
    'Authorization': `Bearer ${getToken()}`
  }
});

export const associateAccountUsers = (data) => msVenueAxios.post(`account-users?organization=${data.organization}`, data, {
  headers: {
    'Authorization': `Bearer ${getToken()}`
  }
});

export const getAccount = id => msVenueAxios.get(`accounts/${id}`, {
  headers: {
    'Authorization': `Bearer ${getToken()}`
  }
});