import { msCategoryAxios } from '../config';
import { getToken } from '../helpers';

export const addImage = data => msCategoryAxios.post(`images?organization=${data.organization}`, data, {
  headers: {
    'Authorization': `Bearer ${getToken()}`
  }
});

export const deleteImage = id => msCategoryAxios.delete(`images/${id}`, {
  headers: {
    'Authorization': `Bearer ${getToken()}`
  }
});

export const getImage = id => msCategoryAxios.get(`images/${id}`, {
  headers: {
    'Authorization': `Bearer ${getToken()}`
  }
});
