import React from 'react';
import logo from '../images/logo.svg';
import darkLogo from '../images/logo-dark.svg';
import beigeLogo from '../images/logo-beige.svg';

export default function Logo(props) {
  const alt = props.alt ? props.alt : "VivaCity";
  switch (props.type) {
    case 'beige':
      return <img alt={alt} src={beigeLogo} />;
    case 'dark':
      return <img alt={alt} src={darkLogo} />;
    default:
      return <img alt={alt} src={logo} />;
  }
}