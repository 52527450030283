import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { commitStep, getStepLoading, getData } from '../../store/onboarding';
import { useForm } from 'react-hook-form';
import MobilePreview from '../MobilePreview';
import FileUpload from '../FileUpload';
import { blob2file } from '../../helpers';
import { Button } from 'antd';

function ProfilePictureForm() {
  const { handleSubmit } = useForm();

  const dispatch = useDispatch();
  const [logo, setLogo] = useState();
  const [logoFile, setLogoFile] = useState();
  const [background, setBackground] = useState();
  const [backgroundFile, setBackgroundFile] = useState();
  const [logoError, setLogoError] = useState();
  const [backgroundError, setBackgroundError] = useState();
  const loading = useSelector(getStepLoading);
  const storeData = useSelector(getData);

  const onSubmit = () => {
    switch (true) {
      case !logo:
        setLogoError('Logo is required');
        break;
      case !background:
        setBackgroundError('Profile Image is required');
        break;
      default:
        dispatch(commitStep({
          step: 2,
          data: {
            logo: logoFile,
            background: backgroundFile
          },
          nextStep: 'upload-menus'
        }));
        break;
    }
  };

  const onLogoImageChange = blob => {
    const reader = new FileReader();
    reader.onloadend = () => {
      setLogo(reader.result);
      setLogoError(false);
    };
    reader.readAsDataURL(blob);

    setLogoFile(blob2file(blob, blob.name));
  };

  const onProfileImageChange = files => {
    if (files && files.length > 0) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setBackground(reader.result);
        setBackgroundError(false);
      };
      reader.readAsDataURL(files[0]);
      setBackgroundFile(files[0]);
    }
  };

  const logoFileName = storeData.primaryImage && storeData.primaryImage.url ? storeData.primaryImage.url.split('/').pop() : null;

  const renderError = error => <p className="error">{error}</p>;

  useEffect(() => {
    if (storeData) {
      if (storeData.primaryImage && storeData.primaryImage.url) {
        const request = new XMLHttpRequest();
        request.open('GET', storeData.primaryImage.url, true);
        request.responseType = 'blob';
        request.onload = function() {
          onLogoImageChange(request.response)
        };
        request.send();
      }
      if (storeData.entryImage && storeData.entryImage.url) {
        setBackground(storeData.entryImage.url);
      }
    }
  }, [storeData]);

  return (
    <form className="flex row space-between" onSubmit={handleSubmit(onSubmit)}>
      <div className="profile-form flex flex-start column">
        <legend>Profile Image</legend>
        <p>
          Please select a picture which best shows off the experience on offer at your venue(s).<br />
          We suggest a beautiful image of the venue or a delicious plate of food.
        </p>

        <FileUpload
          buttonName="Upload Logo"
          name="venueLogo"
          label="* Upload Venue Logo"
          requireText={<>Upload a JPEG or PNG logo file.<br />Max size 512Kb</>}
          cropper={true}
          accept={['jpg', 'jpeg', 'png']}
          maxSize={512}
          error={logoError && renderError(logoError)}
          onChange={onLogoImageChange}
          defaultSrc={logo}
          defaultSrcName={logoFileName}
        />

        <FileUpload
          buttonName="Upload Profile"
          name="profileImage"
          label="* Upload Profile Image"
          accept={['jpg', 'jpeg', 'png']}
          maxSize={2048}
          error={backgroundError && renderError(backgroundError)}
          requireText={<>Upload a JPEG or PNG file for your profile background.<br />Max size 2Mb</>}
          onChange={onProfileImageChange}
        />

      </div>
      <aside>
        <legend>Diner View of your venue Landing Page</legend>
        <MobilePreview background={background} />
      </aside>
      <div className="button-wrap flex column flex-end">
        <Button type="primary" size="large" loading={loading} htmlType="submit">
          Next
        </Button>
      </div>
    </form>
  );
}

export default ProfilePictureForm;