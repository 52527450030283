import auth0 from 'auth0-js';
import config from '../config';

const webAuth = new auth0.WebAuth(config.auth0);

function login({ email, password }) {
  return new Promise((resolve, reject) => {
    webAuth.login({
      realm: config.auth0.realm,
      email: email,
      password: password
    }, (err, result) => {
      if (err) reject(err);
      resolve(result);
    });
  });
}

function handleAuthentication() {
  return new Promise((resolve, reject) => {
    webAuth.parseHash((err, authResult) => {
      if (err) reject(err);
      if (authResult && authResult.accessToken && authResult.idToken) {
        webAuth.client.userInfo(authResult.accessToken,
          function (err, user) {
            if (err) reject(err);
            resolve({
              authResult,
              user
            });
          });
      }
    });
  });
}

function signup({ email, password }) {
  return new Promise((resolve, reject) => {
    webAuth.signup({
      connection: config.auth0.realm,
      email: email,
      password: password
    }, (err, result) => {
      if (err) reject(err);
      resolve(result);
    });
  })
}

function resetPassword(email) {
  return new Promise((resolve, reject) => {
    webAuth.changePassword({ connection: config.auth0.realm, email },
      (err, result) => {
        if (err) reject(err);
        resolve(result);
      });
  });
}

export {
  login,
  signup,
  resetPassword,
  handleAuthentication
};