import { store } from './store';
import config from './config';

export const getToken = () => {
  const state = store.getState();
  return state.session.authentication.id_token;
};

export const getVenueShortName = () => {
  const state = store.getState();
  return state.onboarding.data.short_name;
};

export function normalizeFileName(value) {
  const normalizeValue = value
    .toLowerCase()
    .replace(/\s+/g, '-')
    .replace(/[^a-zA-Z0-9.-]/g, '');
  return normalizeValue;
};

export const generateShortName = (value) => {
  const result = value === undefined ? '' : value.replace(/\W+/g, '-').toLowerCase();
  return result;
};

export const blob2file = (blob, fileName) => {
  return new File([blob], fileName, { lastModified: new Date() });
};

export const getParams = (url) => {
  let params = {};
  const parser = document.createElement('a');
  parser.href = url;
  const query = parser.search.substring(1);
  const vars = query.split('&');
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=');
    params[pair[0]] = decodeURIComponent(pair[1].replace(/\+/g, '%20'));
  }
  return params;
};

export const portalRedirectUrl = () => {
  const { id_token, access_token, expires_in } = store.getState().session.authentication;
  return `${config.portalRedirectUri}?id_token=${id_token}&access_token=${access_token}&expires_in=${expires_in}&utm_source=onboarding`;
}