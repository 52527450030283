import React from 'react';
import { useSelector } from 'react-redux';
import { getData } from '../store/onboarding';
import ArrowDown from '../images/arrow-down.svg';

function MobilePreview(props) {

  const data = useSelector(getData);

  return (
    <div className="preview flex justify-center align-center">
      <div
        className="mobile-background"
        style={{ backgroundImage: props.background ? `url(${props.background})` : `none` }}></div>
      <div className="mobile-frame flex column align-center justify-end">
        <h1>{data.name}</h1>
        <h2>{data.cuisine.primary || ''}</h2>
        <img alt={data.name} src={ArrowDown} />
      </div>
    </div>
  );
}

export default MobilePreview;