import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import useWindowSize from '@rehooks/window-size';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { setUsedAuthView } from '../store/session';
import Logo from './Logo';
import { signup, login } from '../services/Authentication';
import { Form, Input, Button } from 'antd';

function PasswordForm() {
  const dispatch = useDispatch();
  const { innerWidth } = useWindowSize();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [ email ] = useState(() => {
    const params = new URLSearchParams(window.location.search);
    let accountSession = JSON.parse(window.sessionStorage.getItem('account'))
    const emailAddress = params.get('emailAddress') || (accountSession && accountSession.emailAddress) || '';
    return emailAddress;
  });

  const validatePassword = (rule, value, callback) => {
    const pattern = /^.*(?=.{8,})(?=.*[a-zA-Z])(?=.*\d)(?=.*[!#$%&? "]).*$/
    if (value && !pattern.test(value)) {
      callback('Your password needs to exceed 8 characters and must contain one uppercase letter, one symbol (ex: !#$%&?), and a number.');
    } else {
      callback();
    }
  };

  const onSubmit = async data => {
    setLoading(true);
    dispatch(setUsedAuthView('signup'));
    try {
      await signup(data);
      await login(data);
      setLoading(false);
    } catch ({ description }) {
      setLoading(false);
      toast.error(description);
    }
  };

  const onSubmitFailed = (error) => {
    console.log('Failed:', error);
    toast.error(error);
  };

  return (
    <>
      {innerWidth > 640 && (
        <header>
          <h1>Welcome to</h1>
          <Logo type="dark" />
        </header>
      )}
      <Form
        layout="vertical"
        form={form}
        onFinish={onSubmit}
        onFinishFailed={onSubmitFailed}
        initialValues={{email}}
      >
        <legend>Create your Login Information</legend>
        <Form.Item 
          name="email" 
          rules={[
            {
              type: 'email',
              message: 'Entered value needs to be an email address.'
            },
            {
              required: true,
              message: 'Please input your email address.'
            }
          ]}
        >
          <Input size="large" placeholder="Email" />
        </Form.Item>
        <Form.Item 
          name="password"
          rules={[
            {
              required: true,
              message: 'Please input your password.'
            },
            { validator: validatePassword }
          ]}
        >
          <Input.Password size="large" placeholder="Password" />
        </Form.Item>
        <Form.Item
          name="confirmPassword"
          dependencies={['password']}
          rules={[
            {
              required: true,
              message: 'Please input your confirm password.'
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject('Password confirmation must match password.');
              }
            })
          ]}
        >
          <Input.Password size="large" placeholder="Confirm Password" />
        </Form.Item>
        <Button 
          type="primary" 
          size="large" 
          loading={loading} 
          htmlType="submit"
        >Sign Up</Button>
      </Form>
      <div className="form-footer">Already have an account? <Link to="/auth">Sign in</Link></div>
    </>
  );
}

export default PasswordForm;