import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUser, getAccountData } from '../../store/session';
import { commitStep, getPaymentComplete, setStep } from '../../store/onboarding';
import Chargebee from '../../services/Chargebee';
import PaymentPackage from '../PaymentPackage';
import config from '../../config';
import { Row, Col } from 'antd';

function PaymentSetup() {
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const account = useSelector(getAccountData);
  const paymentCompleted = useSelector(getPaymentComplete);
  const [nextStep] = useState('job-done');

  const handlePaymentComplete = data => {
    dispatch(commitStep({
      step: 4,
      data: {
        ...data
      },
      nextStep
    }));
  };

  const handlePaymentClosed = () => {
    console.log(`Payment Closed`);
  };

  const handlePackage = name => {
    const customer = {
      first_name: "",
      last_name: "",
      email: user.email,
      company: account.name,
      billing_address: {
        first_name: "",
        last_name: "",
        company: account.name,
        phone: account.phone,
        line1: "",
        line2: ""
      }
    };

    const handler = new Chargebee(handlePaymentComplete, handlePaymentClosed);
    handler.checkoutPackage(name, customer);
  };

  useEffect(() => {
    if (paymentCompleted) {
      dispatch(setStep(`/${nextStep}`));
    }
  });

  return (
    <Row justify="space-between" className="full-width">
      <legend>Payment Setup</legend>
      <Col xs={24} sm={24} md={10} lg={10} xl={10}>
        <PaymentPackage
          planTitle="Starter"
          title="1. Your Quarterly Plan"
          features={[{
            text: `Easy Setup`
          }, {
            text: `QR Code for a Contactless Experience`
          }, {
            text: `Dashboard & Customer Insights`,
            mark: true
          }]}
          period="Quarterly"
          periodDesc="Quarter to Quarter Contract"
          amountText="Per Month"
          amount="£35"
          autoRenews={true}
          action={() => handlePackage(config.chargebeeStarterMonthlyPlan)}
        />
      </Col>
      <Col xs={24} sm={24} md={10} lg={10} xl={10}>
        <PaymentPackage
          planTitle="Starter"
          title="2. Your Annual Plan"
          features={[{
            text: `Easy Setup`
          }, {
            text: `QR Code for a Contactless Experience`
          }, {
            text: `Dashboard & Customer Insights`,
            mark: true
          }]}
          period="Annual"
          periodDesc="Save 10% by committing to a 12-Month plan"
          amountText="Per Year"
          amount="£378"
          action={() => handlePackage(config.chargebeeStarterAnnualPlan)}
        />
      </Col>
    </Row>
  );
}

export default PaymentSetup;